.temaSite{   
    font-family: 'Segoe UI  Regular';
    background: linear-gradient(#000,transparent),linear-gradient(to top left,#022f20,transparent),linear-gradient(to top right,#000800,transparent);
    color: #fff;
    display: block;
    
}
.menuSite{ 
    text-transform: uppercase;
    transition: all .3s ease; 
    font-family: 'Segoe UI  Bold';
}
.titulo{
  font-family: 'Segoe';
}
.temaSite a{   
    text-decoration: none !important;
}
.btCadastro{ 
    font-family: 'Segoe UI  Bold';
    text-transform: uppercase; 
    color: #3AFFCA;
}

html,body{ 
    position: relative; 
    width: 100%;
    text-align: center; 
    margin: 0 auto;
    overflow: hidden; 
    color: #ffffff;
    background: rgb(0,0,0); 
    box-sizing: border-box;
} 
html{  
    height: 100%;  overflow-y: auto;
    scroll-behavior: smooth;
} 
.btCadastro:hover{   
    color: #3AFFCA;
}
/* Cor de fundo do autocomplete */
input:-webkit-autofill ,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #09121D inset;  
    border: 1px solid #3AFFCA; 
}

/* Cor do texto do autocomplete */
input:-webkit-autofill { 
    -webkit-text-fill-color: #3AFFCA !important;  
    border-color: transparent; 
}
::-webkit-scrollbar-track
{
	 
	border-radius: 0px;
	background-color: var(--chakra-colors-scroll-bg);
  
}

::-webkit-scrollbar
{
	width: var(--chakra-colors-scroll-largura);
  height: var(--chakra-colors-scroll-largura);
	background-color: var(--chakra-colors-scroll-barra);
}

::-webkit-scrollbar-thumb
{
	border-radius: var(--chakra-colors-scroll-borda);
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--chakra-colors-scroll-barra);
}
.btLogin{
    --path: 0px 0px,calc(100% - 10px) 0,130% 100%,calc(100% - 10px) 100%,14px 100%,0px calc(100% - 10px);
    align-items: center;
    background-position: 0 0;
    background-size: 200% 100%; 
    -webkit-clip-path: polygon(var(--path));
    clip-path: polygon(var(--path)); 
    font-size: 16px;  
    justify-content: center;
    line-height: 48px;
    padding: 0px 30px;
    color: #000800;
    background: #3AFFCA;
    text-transform: uppercase;
    transition: all .3s ease; 
    font-family: 'Segoe UI  Bold';
}
.btLogin:hover{
    color:   #3AFFCA;;
    background: #022f20;
}

.btGeral{
    --path: 0px 0px,calc(100% - 10px) 0,130% 100%,calc(100% - 10px) 100%,14px 100%,0px calc(100% - 10px);
    align-items: center;
    background-position: 0 0;
    background-size: 200% 100%; 
    -webkit-clip-path: polygon(var(--path));
    clip-path: polygon(var(--path)); 
    font-size: 16px;  
    justify-content: center;
    line-height: 48px;
    padding: 0px 30px;
    color: #000800;
    background: #3AFFCA;
    text-transform: uppercase;
    transition: all .3s ease; 
    font-family: 'Segoe UI  Bold';
}
.btLogin:hover{
    color:   #3AFFCA;;
    background: #022f20;
} 
@font-face {
  font-family: "Barlow";
  src: url("fonts/Barlow-Regular.ttf");
}
@font-face {
  font-family: "Barlow";
  src: url("fonts/Barlow-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Segoe";
  src: url("fonts/Segoe UI.woff");
}
@font-face {
  font-family: "Segoe";
  src: url("fonts/Segoe UI Bold.woff");
  font-weight: bold;
}

@font-face {
  font-family: "Space";
  src: url("fonts/SpaceGrotesk-Regular.woff");
}
@font-face {
  font-family: "Space";
  src: url("fonts/SpaceGrotesk-Bold.woff");
  font-weight: bold;
}

@font-face {
  font-family: "Mukta";
  src: url("fonts/Mukta-Regular.ttf");
}
@font-face {
  font-family: "Mukta";
  src: url("fonts/Mukta-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Mukta";
  src: url("fonts/Mukta-Light.ttf");
  font-style: Light;
}
@font-face {
  font-family: "Mukta";
  src: url("fonts/Mukta-ExtraBold.ttf");
  font-style: bolder;
}
@font-face {
  font-family: "Mukta";
  src: url("fonts/Mukta-Medium.ttf");
  font-style: medium;
} 
/* 

@font-face {
    font-family: 'Segoe UI  Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI  Regular'), url('./fonts/SpaceGrotesk-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI  Light';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI  Light'), url('./fonts/SpaceGrotesk-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI  Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI  Medium'), url('./fonts/SpaceGrotesk-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI  SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI  SemiBold'), url('./fonts/SpaceGrotesk-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Segoe UI  Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Segoe UI  Bold'), url('./fonts/SpaceGrotesk-Bold.woff') format('woff');
    } */

    
div.PhoneInputCountry{
    position: absolute;
    top: 0px;
    left: 0px; 
    height: var(--chakra-sizes-10);
    z-index: 1; 
  } 
  div.PhoneInputCountry select{ 
    position: relative;
    top: 0px;
    z-index: 4; 
    height: var(--chakra-sizes-10);
    width: var(--chakra-sizes-10); 
    background: transparent;
    color: transparent;
    overflow: hidden;
  }
  div.PhoneInputCountry select option{ 
    
    background: transparent;
    color: var(--chakra-colors-tema1-100);
    font-size: 12px;
     height: 30px;

  }
  svg.PhoneInputCountryIconImg{
    position: absolute;
    top   : 14px; 
    left  : 13px;
    z-index: 1; 
    width: 22px;
    height: 22px; 
    color:  var(--chakra-colors-green-500);
  }
  
  img.PhoneInputCountryIconImg{
    position: absolute;
    top: 0px;
    left: 9px; 
    z-index: 1;
    width: 22px;
    height: var(--chakra-sizes-12);
  }
  div.PhoneInput{
    padding: 0px;
  }
  input.PhoneInputInput{
      width: 100%;
      min-width: 0px;
      outline: 2px solid transparent;
      outline-offset: 2px;
      position: relative;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      transition-property: var(--chakra-transition-property-common);
      transition-duration: var(--chakra-transition-duration-normal);
      font-size: var(--chakra-fontSizes-md);
      -webkit-padding-start: var(--chakra-space-4);
      padding-inline-start: var(--chakra-space-4);
      -webkit-padding-end: var(--chakra-space-4);
      padding-inline-end: var(--chakra-space-4);
      height: var(--chakra-sizes-12);
      border-radius: var(--chakra-radii-md);
      border-color: inherit;
      background: transparent;
      border: 0;
      color: var(--chakra-colors-tema1-100);
      text-indent: 28px;
    
  }
  input[type="date"]{
    padding-left: 16px !important;
    padding-right: 16px !important; 
    text-align: right;
  }
 
  ::-webkit-calendar-picker-indicator {
     color-scheme:"dark";
    filter: invert(77%) sepia(56%) saturate(512%) hue-rotate(102deg) brightness(101%) contrast(101%); 
    line-height: 48px;
    width: 0rem;
    position: absolute;
    font-size: 22px;
    display: none;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="32" width="28" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm80 64c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80z"/></svg>')

    
}
  input.PhoneInputInput::placeholder { 
    color:   var(--chakra-colors-tema1-500);
  }
  input.PhoneInputInput:focus{
    border-color: var(--chakra-colors-blue-300);
    box-shadow: 0 0 0 1px var(--chakra-colors-blue-300);
  }
  #cardPaymentBrick_container{  
    opacity: 0;
  }
  #cardPaymentBrick_container form{ 
    padding: 0px; 
  }
  #cardPaymentBrick_container section h1{ 
    padding-bottom: 20px;
  }
  #cardPaymentBrick_container h2{
    text-align: left !important;
    display: none;
  }
  #cardPaymentBrick_container div{
    grid-template-rows: auto !important;
  }
  #cardPaymentBrick_container label{
    text-align: left !important;
    display: none;
 }
 #cardPaymentBrick_container section label{
  text-align: left !important;
  display: flex;
}
div.bgVideo{
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0px;
  top: 0px;
  background: #fff;
}
div.bgVideo div.videoBox{
  position: relative; 
  width: 100%; 
  height: 100%;
}
div.bgVideo div.videoBox video{
  position: relative; 
  width: 100%; 
  height: 100%;
  vertical-align: middle;
  object-fit: cover; 
  opacity: .3;
  filter: blur(3px);
}
div.bgBox{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: hsla(0, 0%, 100%, .4);
  -o-filter: blur(15px);
  filter: blur(15px);
  z-index: 1;
}
  /* UPLOAD */
  div.boxSendUpload{
    position: relative;
    width: 100%;
    border-radius: 20px; 
    height: 100%;  
    display: block;
    overflow: hidden;
    background: rgba(58, 255, 202,0.5);
    border: 10px dashed var(--chakra-colors-green-500);
} 
div.boxSendUpload *{
    cursor: pointer;
} 
div.boxSendUpload input{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;  
    z-index: 3;
    opacity: 0;
}
div.boxSendUpload img{
    position: absolute;
    top: 0; 
    left: 50%; 
    height: 100%;
    transform: translateX(-50%) !important;
    z-index: 1; 
} 
div.boxSendUploadDrag{
    background: var(--clr-neon2);
    opacity: 0.5;
}
div.boxSendCrop{
    position: relative;
    width: 100%;
    height: 400px;
} 
div.boxSendCrop label{
    position: absolute;
    bottom: -15px;
    left: 0px;
    width: 100%;
    line-height: 60px;
    text-align: center; 
    z-index: 0;
    font-size: 14px;
    font-family: 'CentaurBlod';
    color:  #ffffff;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.05);
} 
div.boxSendCropZoom{
    position: relative;
    width: 100%;  
    padding: 40px 30px 0px 30px;
    height: 80px;
    background:#000000;
}
div.boxSendCropZoom label{
    position: absolute;
    left:   0px;
    top:    10px;
    display: block;
    width: 100%; 
    font-family: 'CentaurBlod';  
}
ul.boxSendCropCMD{
    position: relative;
    width: 100%;
    height: 70px;
    background:#000000; 
    text-align: left;
    padding: 10px 20px;
    border-radius: 0 0 20px 20px;
} 

ul.boxSendCropCMD li{
    display: inline-block;
    vertical-align: top;
    line-height: 50px;
} 
ul.boxSendCropCMD li button{
    margin-top: 10px;
    line-height: 30px;  
    padding: 0 15px;
    background: var(--clr-neon);
} 

 /* 

 #cardPaymentBrick_container div{
  grid-template-rows: auto !important;
 }
 #cardPaymentBrick_container button{
   display: none;
 } */